import { uploadFile } from '@/api/upload'
import snackMixin from '@/mixins/snackMixin'
import { ApplicationFormField } from '@/models-ts/vacancies/Vacancy'
import Vue, { PropType } from 'vue'

export default Vue.extend<any, any, any, any>({
  mixins: [snackMixin],
  props: {
    fieldData: Array as PropType<Array<ApplicationFormField>>,
  },
  data () {
    return {
      value: '',
      file: null,
      loading: false,
    }
  },
  computed: {
    label () {
      return this.fieldData[0].label
    },
    required (): boolean {
      return Boolean(this.fieldData[0].validations?.required)
    },
    textareaData () {
      return this.fieldData.find((data: ApplicationFormField) => data.type === 'textarea')
    },
    fileData () {
      return this.fieldData.find((data: ApplicationFormField) => data.type === 'file_upload')
    },
    maxLength () {
      return this.textareaData?.validations?.maxlength
    },
    textareaValidationRules () {
      let rules = []
      if (this.required) {
        rules.push('required')
      }
      if (Number(this.maxLength) > 0) {
        rules.push(`max:${this.fieldData.validations.maxlength}`)
      }
      return rules.join('|')
    },
  },
  methods: {
    onInput (v: string) {
      this.value = v
      this.$emit(
        'input-many',
        [
          { key: this.textareaData.technical.uuid, value: this.value.trim() },
          { key: this.fileData.technical.uuid, value: '' },
        ]
      )
    },
    async onUploadFile (file: UploadedFile) {
      if (file) {
        try {
          this.loading = true
          this.file = file
          const { url } = await uploadFile(file.base64)
          if (this.file) {
            this.$emit(
              'input-many',
              [
                { key: this.fileData.technical.uuid, value: url },
                { key: this.textareaData.technical.uuid, value: '' },
              ]
            )
          }
        } catch (e) {
          this.file = null
          console.error(e)
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: `Upload file error`,
          })
        } finally {
          this.loading = false
        }
      }
    },
    onDeleteFile () {
      this.file = null
      this.$emit(
        'input-many',
        [
          { key: this.textareaData.technical.uuid, value: this.value.trim() },
          { key: this.fileData.technical.uuid, value: '' },
        ]
      )
    },
    async validate () {
      this.errors.clear()
      return this.$validator.validateAll()
    },
  }
})
