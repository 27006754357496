import BigNumber from 'bignumber.js'
import Vue from 'vue'
import { mapState } from 'vuex'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import ratesMixin from '@/mixins/ratesMixin'
import responseMixin from '@/mixins/responseMixin'
import { declineOffer as declineGigOfferApi, sendOffer as sendGigOfferApi } from '@/api/gig'
import { declineOffer as declineJobOfferApi, sendOffer as sendJobOfferApi } from '@/api/jobs/offers'
import { TIME_HOURLY } from '@/constants/backend/service'
import { WalletGroup } from '@/constants/blockchain'
import WalletSelect from '@/partials/WalletSelect/WalletSelect.vue'
import GigOffer from '@/models-ts/gigs/GigOffer'
import { GigTimeTypes } from '@/constants/gig/gigTimeTypes'

export default Vue.extend<any, any, any, any>({
  name: 'lx-send-offer-modal',
  mixins: [responseMixin, ratesMixin],
  components: {
    WalletSelect,
  },
  props: {
    applicationId: [String, Number],
    offer: GigOffer,
    gig: Object, // Gig
    initBudget: Number,
    initDays: Number,
    initHours: Number,
    onSuccess: Function
  },
  data () {
    return {
      sending: false,
      wallet: null,
      rate: 0,
      hours: 0,
      days: 1,
      TIME_HOURLY,
      WalletGroup,
    }
  },
  computed: {
    ...mapState({
      walletDefault: (state: any) => state.user.wallet,
      wallets: (state: any) => state.user.wallets,
    }),
    isDevEnv () {
      return process.env.VUE_APP_MODE === 'dev'
    },
    isGigOffer () {
      return !!this.gig
    },
    isHourly () {
      return this.gig?.timeType === GigTimeTypes.HOURLY
    },
    totalAmount () {
      return new BigNumber(this.rate).multipliedBy(this.isHourly ? Number(this.hours) : 1)
    },
  },
  created () {
    this.rate = this.initBudget || 0
    this.hours = this.initHours || 0
    // this.days = this.offer.deadline ? new BigNumber(this.offer.deadline).dividedBy(86400).toString() : 0
    this.days = this.initDays || 0
    // const walletAddress = this.offer.freelancer_wallet || this.walletDefault?.address
    const walletAddress = this.walletDefault?.address
    this.wallet = this.walletDefault
  },
  methods: {
    onCancelClick () {
      this.$emit('close')
    },
    async onSendClick () {
      this.errors.clear()
      const isValid = this.isGigOffer ? await this.gigValidate() : await this.jobValidate()
      if (isValid) {
        try {
          this.sending = true
          await notifiableRequest({
            request: async () => {
              if (this.isGigOffer) {
                await this.sendGigOffer()
              } else {
                await this.sendJobOffer()
              }
            },
            title: 'Send an offer',
            successText: 'You have submitted an offer for this Job.',
            failureText: 'Error submitting offer. Please try again.'
          })
          if (this.onSuccess) {
            this.onSuccess()
          }
          this.$emit('close')
        } finally {
          this.sending = false
        }
      }
    },
    async gigValidate () {
      const isValid = await this.$validator.validateAll()
      let isValidBudget = true
      let rate = this.rate
      if (this.isHourly) {
        rate = rate * this.hours
      }
      if (rate < 15 && !this.isDevEnv) {
        this.errors.add({
          field: 'rate',
          msg: 'The budget must be more than $14.99'
        })
        isValidBudget = false
      } else if (rate > 15000) {
        this.errors.add({
          field: 'rate',
          msg: 'The budget must be less than $15,000'
        })
        isValidBudget = false
      }
      let isValidHours = true
      if (this.isHourlyBased && this.service.time_value < 1) {
        this.errors.add({
          field: 'hours',
          msg: 'The hours must be more than 1'
        })
        isValidHours = false
      }
      return isValid && isValidBudget && isValidHours
    },
    async jobValidate () {
      const isValid = await this.$validator.validateAll()
      let isValidBudget = true
      if (this.rate < 15 && !this.isDevEnv) {
        this.errors.add({
          field: 'rate',
          msg: 'The budget must be more than $14.99'
        })
        isValidBudget = false
      } else if (this.rate > 15000) {
        this.errors.add({
          field: 'rate',
          msg: 'The budget must be less than $15,000'
        })
        isValidBudget = false
      }
      return isValid && isValidBudget
    },
    async sendGigOffer () {
      if (this.offer) {
        await declineGigOfferApi({
          id: this.offer.id,
          comment: 'Not specified',
        })
      }
      await sendGigOfferApi({
        rate: this.rate,
        time_value: +this.hours,
        time_type: +this.gig.timeType,
        deadline: new BigNumber(this.days).multipliedBy(86400).toString(),
        freelancer_wallet: this.wallet.address,
        group: this.wallet.group,
        application_id: this.applicationId,
      })
    },
    async sendJobOffer () {
      if (this.offer) {
        await declineJobOfferApi({
          id: this.offer.id,
          comment: 'Not specified',
        })
      }
      await sendJobOfferApi({
        budget: this.rate,
        deadline: new BigNumber(this.days).multipliedBy(86400).toString(),
        freelancer_wallet: this.wallet.address,
        group: this.wallet.group,
        application_id: this.applicationId,
      })
    },
  },
})
