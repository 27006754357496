import { render, staticRenderFns } from "./FormAttachFileOrText.htm?vue&type=template&id=0e701042&scoped=true&"
import script from "./FormAttachFileOrText.ts?vue&type=script&lang=js&"
export * from "./FormAttachFileOrText.ts?vue&type=script&lang=js&"
import style0 from "./FormAttachFileOrText.scss?vue&type=style&index=0&id=0e701042&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e701042",
  null
  
)

export default component.exports