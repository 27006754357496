import VueTypes from 'vue-types'
import responseMixin from '@/mixins/responseMixin'
import { getBase64Image } from '@/api/gig'
import snackMixin from '@/mixins/snackMixin'
import UnsplashTab from './UnsplashTab/UnsplashTab.vue'
import GalleryTab from './GalleryTab/GalleryTab.vue'

export default {
  name: 'lx-gig-banner-upload-modal',
  mixins: [responseMixin, snackMixin],
  components: {
    GalleryTab,
    UnsplashTab,
  },
  props: {
    onSuccess: VueTypes.func,
  },
  data () {
    return {
      tab: 'upload',
      saving: false,
      selected: {
        upload: null,
        gallery: null,
        unsplash: null,
        link: null,
      },
      imageByLinkError: false,
      imageByLinkLoaded: false,
    }
  },
  computed: {
    uploadFile () {
      return this.selected.upload ? [this.selected.upload] : []
    },
    isSubmitDisabled () {
      switch (this.tab) {
        case 'upload': {
          return !this.selected.upload
        }
        case 'gallery': {
          return !this.selected.gallery
        }
        case 'unsplash': {
          return !this.selected.unsplash
        }
        case 'link': {
          return !this.imageByLinkLoaded
        }
      }
    },
  },
  methods: {
    onSelect (image) {
      switch (this.tab) {
        case 'upload': {
          this.selected.upload = image
          break
        }
        case 'gallery': {
          this.selected.gallery = image
          break
        }
        case 'unsplash': {
          this.selected.unsplash = image
          break
        }
        case 'link': {
          this.selected.link = image
        }
      }
      // this.onClickSave()
    },
    onRemoveBanner () {
      this.selected.upload = null
    },
    onImageLinkInput () {
      this.imageByLinkLoaded = false
      this.imageByLinkError = false
    },
    onLinkImageLoaded () {
      this.imageByLinkLoaded = true
      this.imageByLinkError = false
    },
    onLinkImageError () {
      this.imageByLinkLoaded = false
      this.imageByLinkError = true
    },
    async onClickSave () {
      try {
        this.saving = true
        let file = {}
        try {
          if (this.tab === 'upload') {
            file = this.selected.upload
          } else if (this.tab === 'gallery') {
            const origin = process.env.NODE_ENV === 'development' ? 'https://develop.laborx.io' : process.env.VUE_APP_FRONTEND_URL
            const { base64 } = await getBase64Image(`${origin}${this.selected.gallery.url}`)
            file.base64 = base64
            file.description = this.selected.gallery.description
          } else if (this.tab === 'unsplash') {
            const url = new URL(this.selected.unsplash.url)
            url.searchParams.set('w', 632)
            url.searchParams.set('h', 316)
            const { base64 } = await getBase64Image(url.toString())
            file.base64 = base64
            file.description = this.selected.unsplash.description
          } else if (this.tab === 'link') {
            const { base64 } = await getBase64Image(this.selected.link)
            file.base64 = base64
            file.description = 'Banner'
          }
        } catch (e) {
          const errMsg = e.response?.data?.validation?.file
          if (errMsg) {
            this.openSnackbar({
              type: this.SnackTypes.FAILURE,
              text: errMsg,
            })
            return
          } else {
            throw e
          }
        }
        this.onSuccess(file, this.tab)
        this.$emit('close')
      } catch (e) {
        this.parseError(e)
      } finally {
        this.saving = false
      }
    },
  },
}
