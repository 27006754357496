const components = {}
if (process.client || typeof process.client === 'undefined') {
  components['Stack'] = () => {
    return {
      component: new Promise(async (resolve) => {
        const { Stack } = await import(/* webpackChunkName: "stack-grid" */ 'vue-stack-grid')
        resolve(Stack)
      }),
    }
  }
  components['StackItem'] = () => {
    return {
      component: new Promise(async (resolve) => {
        const { StackItem } = await import(/* webpackChunkName: "stack-grid" */ 'vue-stack-grid')
        resolve(StackItem)
      }),
    }
  }
}

export default {
  components: components,
  model: { event: 'select' },
  props: {
    images: Array,
    value: Number,
  },
  data () {
    return {
      loadedCount: 0,
    }
  },
  methods: {
    onClickImage (index) {
      this.$emit('select', index)
    },
    onImageLoaded () {
      this.loadedCount++
      if (this.loadedCount >= this.images.length) {
        this.$emit('images-loaded')
      }
    },
    getUrl (image) {
      return typeof image === 'string' ? image : image.urls.small
    }
  }
}
