import { ApplicationFormField } from '@/models-ts/vacancies/Vacancy'
import Vue, { PropType } from 'vue'

interface Option {
  key: string
  name: string
}

interface Data {
  value?: Option | null
}

export default Vue.extend<any, any, any, any>({
  props: {
    fieldData: Object as PropType<ApplicationFormField>,
  },
  data (): Data {
    return {
      value: null,
    }
  },
  computed: {
    required (): boolean {
      return Boolean(this.fieldData?.validations?.required)
    },
    options () {
      return (this.fieldData as ApplicationFormField).options?.map(opt => ({
        key: opt.value,
        name: opt.title,
      }))
    },
    validationRules () {
      let rules = []
      if (this.required) {
        rules.push('required')
      }
      return rules.join('|')
    },
  },
  methods: {
    onInput (v?: Option) {
      this.value = v
      this.$emit('input', { key: this.fieldData.technical.uuid, value: this.value?.key })
    },
    async validate () {
      this.errors.clear()
      return this.$validator.validateAll()
    },
  }
})
