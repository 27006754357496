import { mapState } from 'vuex'
import { PROFILE_SETTINGS } from '@/constants/routes'

export default {
  name: 'lx-profile-filling-modal',
  computed: {
    ...mapState({
      hasSkills: state => state.user?.hasSkills,
    }),
  },
  mounted () {
    console.error('User has not skills', this.hasSkills)
  },
  methods: {
    onClickSkip () {
      this.$emit('close')
    },
    onClickYes () {
      this.$router.push({ name: PROFILE_SETTINGS, hash: '#skills' }).catch(() => {})
      this.$emit('close')
    },
  },
}
