import Vue, { PropType } from 'vue'
import WalletSelect from '@/partials/WalletSelect/WalletSelect.vue'
import { mapState } from 'vuex'
import { RootState } from '@/store'

export type JobApplicationFormData = {
  comment: string
  budget: number // in usd
  deadline: number // in seconds
  freelancerWallet?: string,
}

export default Vue.extend<any, any, any, any>({
  name: 'send-job-application',
  components: {
    WalletSelect,
  },
  props: {
    budget: String,
    deadline: [Number, String],
    onSuccess: Function as PropType<(application: JobApplicationFormData) => void>,
  },
  data () {
    return {
      sending: false,
      form: {
        comment: '',
        deadline: 1,
        budget: 5,
        wallet: null,
      },
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
    }),
    isDevEnv () {
      return process.env.VUE_APP_MODE === 'dev'
    },
  },
  created () {
    if (this.budget) {
      this.form.budget = Number(this.budget)
    }
    if (this.deadline) {
      this.form.deadline = Number(this.deadline)
    }
  },
  methods: {
    async onFormSubmit () {
      try {
        this.errors.clear()
        let isValidBudget = true
        if (this.form.budget < 15) {
          this.errors.add({
            field: 'budget',
            msg: 'The budget must be more than $14.99'
          })
          isValidBudget = false
        } else if (this.form.budget > 15000) {
          this.errors.add({
            field: 'budget',
            msg: 'The budget must be less than $15,000'
          })
          isValidBudget = false
        }
        const isValid = await this.$validator.validateAll()
        if (isValidBudget && isValid) {
          this.sending = true
          if (this.onSuccess) {
            const applicationData: JobApplicationFormData = {
              comment: this.form.comment.trim(),
              budget: this.form.budget,
              deadline: this.form.deadline * 86400, // in seconds
              freelancerWallet: this.form.wallet?.address,
            }
            await this.onSuccess(applicationData)
          }
          this.$emit('close')
        }
      } finally {
        this.sending = false
      }
    },
    onCancelClick () {
      this.$emit('close')
    },
  },
})
