import { ApplicationFormField } from '@/models-ts/vacancies/Vacancy'
import Vue, { PropType } from 'vue'

export default Vue.extend<any, any, any, any>({
  props: {
    fieldData: Object as PropType<ApplicationFormField>,
  },
  data () {
    return {
      value: ''
    }
  },
  computed: {
    required (): boolean {
      return Boolean(this.fieldData?.validations?.required)
    },
    validationRules () {
      let rules = []
      if (this.required) {
        rules.push('required')
      }
      if (Number(this.fieldData?.validations?.maxlength) > 0) {
        rules.push(`max:${this.fieldData.validations.maxlength}`)
      }
      return rules.join('|')
    },
  },
  methods: {
    onInput (v: string) {
      this.value = v
      this.$emit('input', { key: this.fieldData.technical.uuid, value: this.value.trim() })
    },
    async validate () {
      this.errors.clear()
      return this.$validator.validateAll()
    },
  }
})
