import { ApplicationFormField } from '@/models-ts/vacancies/Vacancy'
import Vue, { PropType } from 'vue'

export default Vue.extend<any, any, any, any>({
  props: {
    fieldData: Array as PropType<Array<ApplicationFormField>>,
  },
  data () {
    return {
      value: null,
      addonsValue: {},
    }
  },
  computed: {
    label () {
      return this.fieldData[0].label
    },
    required (): boolean {
      return Boolean(this.fieldData[0].validations?.required)
    },
    options () {
      return this.fieldData.map((field: ApplicationFormField) => ({
        label: field.option?.title,
        value: field.option?.value,
        uuid: field.technical.uuid,
        inputUUID: field?.addons?.technical.uuid,
      }))
    },
    uuids () {
      return this.fieldData.map((field: ApplicationFormField) => field.technical.uuid)
    },
    selectedValue () {
      return this.value?.value
    },
  },
  created () {
    this.fieldData.forEach((field: ApplicationFormField) => {
      if (field.addons) {
        Vue.set(this.addonsValue, field.addons.technical.uuid, '')
      }
    })
  },
  methods: {
    onInput ({ uuid, value, inputUUID }: { uuid: string, value: string, inputUUID: string | null }) {
      const payload = this.uuids.map((key: string) => ({ key, value: key === uuid ? value : '' }))
      const addons = Object.keys(this.addonsValue).map((key) => ({
        key,
        value: inputUUID && key === inputUUID ? this.addonsValue[inputUUID] : '',
      }))
      this.$emit('input-many', [...payload, ...addons])
    },
    onAddonsInput (uuid: string, value: string) {
      for (const key in this.addonsValue) {
        this.addonsValue[key] = key === uuid ? value : ''
      }
      this.$emit('input-many', Object.entries(this.addonsValue as { [key: string]: string})
        .map(([key, value]) => ({ key, value: value.trim() })))
    },
    async validate () {
      this.errors.clear()
      const isValid = !this.required || Object.values(this.value).some(v => v)
      if (!isValid) {
        this.errors.add({
          field: 'checkbox',
          msg: 'The field is required',
        })
      }
      return isValid
    },
  }
})
