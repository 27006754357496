import Vue, { PropType } from 'vue'

type ImageViewing = {
  id: number
  description: string
  src: string
}

export default Vue.extend<any, any, any, any>({
  name: 'lx-image-viewer-modal',
  props: {
    images: Array as PropType<Array<ImageViewing>>,
    title: String,
    initialImage: Number,
  },
  data () {
    return {
      swiper: null,
      imageName: '',
    }
  },
  async mounted () {
    this.imageName = this.images[0]?.description
    // @ts-ignore
    const Swiper = (await import(/* webpackChunkName: "swiper" */ 'swiper')).default
    this.swiper = new Swiper('.image-viewer > .image-swiper', {
      autoHeight: true,
      spaceBetween: 0,
      pagination: {
        el: '.image-viewer > .swiper-pagination',
        clickable: true,
      },
      zoom: true,
      slidesPerView: 1,
      clickable: true,
      initialSlide: this.initialImage ? this.initialImage : 0,
    })
    this.swiper.on('activeIndexChange', this.activeIndexChange)
  },
  methods: {
    activeIndexChange () {
      this.imageName = this.images[this.swiper.activeIndex]?.description
    },
  },
})
