import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import JobApplication from '@/models-ts/job/JobApplication'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import sleep from '@/utils/sleep'
import { applyOffer } from '@/api/jobs/offers'

export default Vue.extend<any, any, any, any>({
  name: 'lx-accept-talent-offer',
  components: { UserInfo },
  props: {
    application: JobApplication,
    onSuccess: Function,
  },
  data () {
    return {
      sending: false,
    }
  },
  computed: {
    ...mapGetters({
      backendConfig: 'user/getConfigMap',
    }),
    user () {
      return this.application.freelancer
    },
  },
  methods: {
    ...mapMutations({
      setAcceptingOffer: 'chatNew/setAcceptingOffer',
    }),
    async onSubmit () {
      try {
        this.setAcceptingOffer(this.application.offer.id)
        this.sending = true
        await notifiableRequest({
          request: async () => {
            await applyOffer(this.application.offer.id)
            await sleep(this.backendConfig['CREATE_CONTRACT_BACKEND_FLOW_TIMEOUT'] || 2000)
          },
          title: 'Offer accepted',
          successText: `You have accepted an offer for this Job.`,
          failureText: 'Error accepting offer. Please try again.'
        })
      } finally {
        this.setAcceptingOffer(null)
        this.sending = true
      }
      this.$emit('close')
      this.onSuccess()
    }
  },
})
