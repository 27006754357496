import InfiniteLoading from 'vue-infinite-loading'
import Gallery from '../Gallery/Gallery.vue'

const GALLERY_PATH = '/static/images/gallery'
const PAGE_SIZE = 12

export default {
  components: {
    InfiniteLoading,
    Gallery,
  },
  data () {
    return {
      gallery: [],
      page: 1,
      selected: null,
      infiniteId: 0,
      imageLoading: true,
    }
  },
  computed: {
    gallerySize () {
      return 25
    },
    images () {
      return this.gallery.slice(0, this.page * PAGE_SIZE)
    },
    hasInfinity () {
      return this.infiniteId && !this.imageLoading && this.images.length < this.gallerySize
    },
  },
  created () {
    let path = GALLERY_PATH
    for (let i = 1; i <= this.gallerySize; i++) {
      this.gallery.push(`${path}/${i}.png`)
    }
    if (PAGE_SIZE < this.gallerySize) {
      this.infiniteId = +new Date()
    }
  },
  methods: {
    onSelectImage (index) {
      this.selected = index
      this.$emit('select', {
        url: this.images[this.selected],
        description: 'Banner',
      })
    },
    onImageLoaded () {
      this.$nextTick(() => {
        this.imageLoading = false
      })
    },
    onLoadMore ($state) {
      if (this.images.length < this.gallerySize) {
        this.imageLoading = true
        this.page++
        return $state.loaded()
      }
      return $state.complete()
    },
  }
}
