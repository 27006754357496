import Vue, { PropType } from 'vue'
import get from 'lodash/get'
import uniqueId from 'lodash/uniqueId'
import responseMixin from '@/mixins/responseMixin'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import { getTxLink } from '@/utils-ts/transaction'
import { startDispute as startDisputeGig, DisputeData } from '@/api/gig'
import snackMixin from '@/mixins/snackMixin'
import { awaitTxBackend } from '@/servicies/backend/transaction'
import { METHOD_BLOCK_CONTRACT, METHOD_SUCCESS_EVENT } from '@/constants/blockchain/contract'
import ErrorMatcher from '@/utils/ErrorMatcher'
import { startDispute as startDisputeJob } from '@/api/jobs/job'
import { DISPUTE_GIG_TYPE } from '@/constants/components/CreateDispute'

export default Vue.extend<any, any, any, any>({
  name: 'lx-create-dispute-modal',
  mixins: [responseMixin, snackMixin],
  props: {
    onSuccess: Function,
    disputeData: Object as PropType<CreateDisputeProps>,
  },
  data () {
    return {
      sending: false,
      comment: '',
      files: [],
    }
  },
  computed: {
    disputeParams () {
      const params: DisputeData = {
        comment: (this.comment || '').trim(),
      }
      if (this.files.length > 0) {
        params.files = this.files.map((fl: UploadedFile) => ({
          base64: fl.base64,
          description: fl.description,
        }))
      }
      return params
    }
  },
  methods: {
    onUploadFile (file: UploadedFile) {
      if (file) {
        this.files.push(file)
      }
    },
    onRemoveFile (file: UploadedFile) {
      this.files = this.files.filter((fl: UploadedFile) => fl.key !== file.key)
    },
    async onSubmit () {
      try {
        if (!this.sending && await this.$validator.validateAll()) {
          this.sending = true
          await notifiableRequest({
            request: async () => {
              const snackId = uniqueId()
              const startDisputeQuery = this.disputeData.type === DISPUTE_GIG_TYPE ? startDisputeGig : startDisputeJob
              const { tx_id } = await startDisputeQuery({
                id: this.disputeData.id,
                params: this.disputeParams,
              })
              const link = getTxLink({ blockchain: this.disputeData.blockchain, tx: tx_id })
              this.openSnackbar({
                id: snackId,
                type: this.SnackTypes.LOADING,
                // eslint-disable-next-line max-len
                text: `Waiting for <a href="${link}" target="_blank" rel="noopener nofollow" style="text-decoration:none;">transaction</a> confirmation from the network`,
              })
              // @ts-ignore
              await awaitTxBackend({
                blockchain: this.disputeData.blockchain,
                txId: tx_id,
                event: METHOD_SUCCESS_EVENT[METHOD_BLOCK_CONTRACT]
              })
              this.closeSnackbar(snackId)
            },
            title: 'Dispute initiated',
            successText: `Your dispute has been initaited. Please standby as our support team adjudicate.`,
            // eslint-disable-next-line max-len
            failureText: (e) => ErrorMatcher.isConflict(e) ? get(e, 'response.data.message', 'Error start dispute') : 'Error initiating dispute. Please try again.'
          })
          if (this.onSuccess) {
            this.onSuccess()
          }
          this.$emit('close')
        }
      } finally {
        this.sending = false
      }
    }
  },
})
