import { render, staticRenderFns } from "./GalleryTab.htm?vue&type=template&id=653a7fad&scoped=true&"
import script from "./GalleryTab.js?vue&type=script&lang=js&"
export * from "./GalleryTab.js?vue&type=script&lang=js&"
import style0 from "./GalleryTab.scss?vue&type=style&index=0&id=653a7fad&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "653a7fad",
  null
  
)

export default component.exports