import { ApplicationFormField } from '@/models-ts/vacancies/Vacancy'
import Vue, { PropType } from 'vue'

export default Vue.extend<any, any, any, any>({
  props: {
    fieldData: Array as PropType<Array<ApplicationFormField>>,
  },
  data () {
    return {
      value: {}
    }
  },
  computed: {
    label () {
      return this.fieldData[0].label
    },
    required (): boolean {
      return Boolean(this.fieldData[0].validations?.required)
    },
  },
  created () {
    this.fieldData.forEach((f: ApplicationFormField) => {
      Vue.set(this.value, f.technical.uuid, false)
    })
  },
  methods: {
    onInput (key: string, v: boolean) {
      this.value[key] = v
      this.$emit('input', {
        key,
        value: v ? this.fieldData.find((f: ApplicationFormField) => f.technical.uuid === key)?.option.value : ''
      })
    },
    async validate () {
      this.errors.clear()
      const isValid = !this.required || Object.values(this.value).some(v => v)
      if (!isValid) {
        this.errors.add({
          field: 'checkbox',
          msg: 'The field is required',
        })
      }
      return isValid
    },
  }
})
