import InfiniteLoading from 'vue-infinite-loading'
import debounce from 'lodash/debounce'
import unsplash from '@/servicies/Unsplash'
import Gallery from '../Gallery/Gallery.vue'

export default {
  components: {
    InfiniteLoading,
    Gallery,
  },
  data () {
    return {
      keyword: '',
      images: [],
      selected: null,
      page: 1,
      totalPages: 0,
      infiniteId: 0,
      unsplashPreloading: false,
      imageLoading: false,
    }
  },
  created () {
    this.initImages()
  },
  computed: {
    hasInfinity () {
      return this.infiniteId && !this.imageLoading && this.page < this.totalPages
    },
  },
  methods: {
    onSearchInput: debounce(function () {
      this.selected = null
      this.initImages()
    }, 500),
    onImageLoaded () {
      this.imageLoading = false
    },
    onSelectImage () {
      const selected = this.images[this.selected]
      this.$emit('select', {
        url: selected.urls.raw || selected.urls.full || selected.urls.small,
        description: selected.alt_description,
      })
    },
    async onLoadMore ($state) {
      try {
        if (this.page < this.totalPages) {
          this.imageLoading = true
          this.page++
          const { images } = await this.search()
          this.images.push(...images)
          return $state.loaded()
        }
        return $state.complete()
      } catch (e) {
        this.parseError(e)
      }
    },
    async initImages () {
      this.unsplashPreloading = true
      this.page = 1
      this.images = []
      const { images, totalPages } = await this.search()
      this.images = images
      this.totalPages = totalPages
      if (this.totalPages > 1) {
        this.infiniteId = +new Date()
      }
      this.unsplashPreloading = false
    },
    async search () {
      const { results, total_pages } = await unsplash.search({ keyword: this.keyword || 'service', page: this.page })
      return { images: results, totalPages: total_pages }
    },
  }
}
