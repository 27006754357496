import { render, staticRenderFns } from "./SendOffer.htm?vue&type=template&id=541b8f71&scoped=true&"
import script from "./SendOffer.ts?vue&type=script&lang=js&"
export * from "./SendOffer.ts?vue&type=script&lang=js&"
import style0 from "./SendOffer.scss?vue&type=style&index=0&id=541b8f71&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "541b8f71",
  null
  
)

export default component.exports