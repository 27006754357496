import Vue from 'vue'
import { mapActions } from 'vuex'
import { STATUS_DRAFT } from '@/constants/job/jobStatuses'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import notifiableRequest from '@/utils-ts/notifiableRequest'

export default Vue.extend<any, any, any, any>({
  mixins: [],
  name: 'lx-unpublish-job-modal',
  props: {
    job: Object,
    onSuccess: Function,
    onFail: Function,
  },
  data: () => ({
    unPublishing: false,
  }),
  methods: {
    ...mapActions({
      unpublish: 'jobDetails/unpublish',
    }),
    async onUnpublishJob () {
      try {
        this.unPublishing = true
        await notifiableRequest({
          request: () => this.unpublish(this.job.id),
          title: 'Unpublish job',
          successText: `Job: <b>${this.job.name}</b> was successfully unpublished`,
          failureText: 'Error unpublishing Job. Please try again.'
        })
        googleAnalyticsV2.send({
          event: 'job-drafted',
          job_id: this.job.id,
        })
        if (this.onSuccess) {
          await this.onSuccess()
        }
        this.$emit('close')
      } catch (e) {
        if (this.onFail) {
          await this.onFail()
        }
      } finally {
        this.unPublishing = false
      }
    }
  }
})
