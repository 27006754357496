export const THIS_ACCOUNT_IS_FAKE = 'This account is fake'
export const ITS_SPAM = 'It’s spam'
export const ITS_SCAM = 'It’s scam'
export const INCLUDES_BAD_LINKS = 'Includes a potentially harmful, malicious or phishing links'
export const LEAVE_FROM_PLATFORM = 'Leads to another platform'
export const OTHER = 'Other'

export const REASONS = [
  THIS_ACCOUNT_IS_FAKE,
  ITS_SPAM,
  ITS_SCAM,
  INCLUDES_BAD_LINKS,
  LEAVE_FROM_PLATFORM,
  OTHER,
]
