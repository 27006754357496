import Vue from 'vue'
import { DEFAULT_GIG_APPLICATION_MSG } from '@/constants/gig/gigMessages'

export type GigApplicationFormData = {
  comment: string
  budget: number // in usd
  deadline: number // in seconds
}

export default Vue.extend<any, any, any, any>({
  name: 'lx-send-application',
  props: {
    budget: String,
    onSuccess: Function,
  },
  data () {
    return {
      sending: false,
      form: {
        comment: '',
        deadline: 1,
        budget: 5,
      }
    }
  },
  computed: {
    isDevEnv () {
      return process.env.VUE_APP_MODE === 'dev'
    },
  },
  created () {
    if (this.budget) {
      this.form.budget = Number(this.budget)
    }
  },
  methods: {
    async onFormSubmit () {
      try {
        this.errors.clear()
        let isValidBudget = true
        if (this.form.budget < 15) {
          this.errors.add({
            field: 'budget',
            msg: 'The budget must be more than $14.99'
          })
          isValidBudget = false
        } else if (this.form.budget > 15000) {
          this.errors.add({
            field: 'budget',
            msg: 'The budget must be less than $15,000'
          })
          isValidBudget = false
        }
        const isValid = await this.$validator.validateAll()
        if (isValidBudget && isValid) {
          this.sending = true
          if (this.onSuccess) {
            const commentTrim = this.form.comment.trim()
            const comment = !commentTrim
              ? DEFAULT_GIG_APPLICATION_MSG
              : commentTrim
            const applicationData: GigApplicationFormData = {
              comment,
              budget: this.form.budget,
              deadline: this.form.deadline * 86400, // in seconds
            }
            await this.onSuccess(applicationData)
          }
          this.$emit('close')
        }
      } finally {
        this.sending = false
      }
    },
    onCancelClick () {
      this.$emit('close')
    },
  },
})
