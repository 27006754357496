import Vue from 'vue'
import Vacancy from '@/models-ts/vacancies/Vacancy'
import { mapActions, mapState } from 'vuex'
import MyVacancyListItem from '@/models-ts/vacancies/MyVacancyListItem'
import { archiveVacancy } from '@/api/vacancies'
import { REASON_FOR_ARCHIVING } from '@/constants/components/quizzes'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  name: 'lx-archive-vacancy-modal',
  props: {
    vacancy: [MyVacancyListItem, Vacancy],
    onComplete: Function,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState<RootState>({
      userId: (state: RootState) => state.user?.id,
    }),
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      archiveVacancy: 'vacancyDetails/archiveVacancy',
    }),
    async onClickArchive () {
      try {
        this.loading = true
        if (this.vacancy instanceof Vacancy) {
          await this.archiveVacancy(this.vacancy.id)
        } else {
          await archiveVacancy(this.vacancy.id)
        }
        if (this.onComplete) {
          this.onComplete()
        }
        this.$emit('close')
        this.openModal({
          component: 'lx-lazy-modal',
          props: {
            factory: import(/* webpackChunkName: "settings-modals" */ '@/modals/Quiz/Quiz.vue'),
            props: {
              type: REASON_FOR_ARCHIVING,
              meta: {
                userId: this.userId,
                vacancyId: this.vacancy.id,
              },
            },
          },
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
  },
})
