import Vue from 'vue'
import ErrorMatcher from '@/utils/ErrorMatcher'
import snackMixin from '@/mixins/snackMixin'
import { REASONS, OTHER } from '@/constants/reportReasons'
import { report, ReportData } from '@/api/reports'

export default Vue.extend<any, any, any, any>({
  name: 'lx-report-user-modal',
  mixins: [snackMixin],
  props: {
    toUserId: {
      type: String,
      required: true,
    },
    toUserName: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      sending: false,
      answered: false,
      reason: null,
      comment: '',
      options: REASONS,
      files: [],
    }
  },
  computed: {
    isOther () {
      return this.reason === OTHER
    },
    reportParams () {
      const params: ReportData = {
        toUserId: this.toUserId,
        text: this.isOther
          ? this.comment
          : this.reason
      }
      if (this.files.length > 0) {
        params.files = this.files.map((fl: UploadedFile) => ({
          base64: fl.base64,
          description: fl.description,
        }))
      }
      return params
    }
  },
  methods: {
    onUploadFile (file: UploadedFile) {
      if (file) {
        this.files.push(file)
      }
    },
    onRemoveFile (file: UploadedFile) {
      this.files = this.files.filter((fl: UploadedFile) => fl.key !== file.key)
    },
    onOptionInput () {
      if (this.isOther) {
        this.$nextTick(() => {
          const commentEl = this.$refs.comment?.$el
          if (commentEl) {
            const textarea = commentEl.querySelector('textarea')
            if (textarea) {
              textarea.focus()
              textarea.scrollIntoViewIfNeeded()
            }
          }
        })
      }
    },
    onCancelClick () {
      this.$emit('close')
    },
    async onSubmit () {
      try {
        this.sending = true
        await report(this.reportParams)
        this.answered = true
        setTimeout(() => this.$emit && this.$emit('close'), 3000)
      } catch (e: any) {
        console.error(e)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: ErrorMatcher.isTooManyRequests(e)
            ? 'Daily report limit reached. Please try again in 24 hours. '
            : 'Error submitting report. Please try again.',
        })
      } finally {
        this.sending = false
      }
    },
  },
})
