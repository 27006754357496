import Vue from 'vue'
import { mapState } from 'vuex'
import File from '@/models-ts/File'
import responseMixin from '@/mixins/responseMixin'
import { getCvs } from '@/api/vacancies'
import { RootState } from '@/store'
import Vacancy, { ApplicationFormField } from '@/models-ts/vacancies/Vacancy'
import { VACANCY_EXTERNAL_APPLY } from '@/constants/routes'
import FormInput from './form-fields/FormInput/FormInput.vue'
import FormSelect from './form-fields/FormSelect/FormSelect.vue'
import FormTextarea from './form-fields/FormTextarea/FormTextarea.vue'
import FormCheckbox from './form-fields/FormCheckbox/FormCheckbox.vue'
import FormSection from './form-fields/FormSection/FormSection.vue'
import FormRadio from './form-fields/FormRadio/FormRadio.vue'
import FormAttachFileOrText from './form-fields/FormAttachFileOrText/FormAttachFileOrText.vue'

export type VacancyApplicationFormData = {
  comment: string
  files: Array<{ base64: string, description: string }>,
  recentFiles: Array<{ id: number }>
  additionalData?: Array<{ uuid: string, value: string }>
}

interface AdditionalField {
  key: string
  component: any
  fieldData: ApplicationFormField | Array<ApplicationFormField>
}

export default Vue.extend<any, any, any, any>({
  name: 'lx-apply-for-full-time-job-modal',
  mixins: [responseMixin],
  props: {
    vacancy: Vacancy,
    onSuccess: Function,
  },
  data () {
    return {
      isLoading: false,
      sending: false,
      letter: '',
      cv: null,
      cvs: [],
      files: [],
      additionalFields: [],
      additionalForm: {},
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
    }),
    cvOptions () {
      return this.cvs.map((file: File) => ({
        key: file.id,
        name: file.description || file.filename,
      }))
    },
    uploadedFile () {
      return this.files?.[0]
    },
  },
  async created () {
    try {
      this.initAdditionalFields()
      if (this.isLoggedIn && !this.additionalFields.length) {
        this.isLoading = true
        const response = await getCvs()
        this.cvs = response.map((fl:any) => File.fromServer(fl))
      }
    } catch (e) {
      this.parseError(e)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    initAdditionalFields () {
      const data = (this.vacancy as Vacancy).applicationForm.filter(field => field.type !== 'hidden')
      const fields = []
      const getComponent = (data: ApplicationFormField) => {
        switch (data.type) {
          case 'input': return FormInput
          case 'select': return FormSelect
          case 'textarea': return FormTextarea
          case 'checkbox': return FormCheckbox
          case 'section': return FormSection
          case 'radio': return FormRadio
          case 'file_upload': return FormAttachFileOrText
        }
      }
      for (const fieldData of data) {
        const component = getComponent(fieldData)
        if (!component) {
          this.$router.push({ name: VACANCY_EXTERNAL_APPLY, params: { id: this.vacancy.id, slug: this.vacancy.slug } })
          this.$emit('close')
          return
        }
        fields.push({ fieldData, component, key: fieldData.technical.uuid })
        Vue.set(this.additionalForm, fieldData.technical.uuid, '')
        if (fieldData.addons?.technical.uuid) {
          Vue.set(this.additionalForm, fieldData.addons?.technical.uuid, '')
        }
      }
      this.additionalFields = fields.reduce((acc, field) => {
        if (field.fieldData.group) {
          const group = acc.find(f => f.key === field.fieldData.group)
          if (Array.isArray(group?.fieldData)) {
            group?.fieldData.push(field.fieldData)
          } else {
            acc.push({
              fieldData: [field.fieldData],
              component: field.fieldData.type === 'textarea' ? FormAttachFileOrText : field.component,
              key: field.fieldData.group,
            })
          }
        } else {
          acc.push(field)
        }
        return acc
      }, [] as AdditionalField[])
    },
    onCancelClick () {
      this.$emit('close')
    },
    onUploadFile (file: UploadedFile) {
      if (file) {
        this.files = [file]
      }
    },
    onDeleteFile () {
      this.files = []
    },
    onAdditionalInput ({ key, value }: { key: string, value: string }) {
      this.additionalForm[key] = value
    },
    onAdditionalManyInput (payload: Array<{ key: string, value: string }>) {
      payload.forEach(item => {
        this.additionalForm[item.key] = item.value
      })
    },
    async onSubmit () {
      try {
        let isValid = await this.$validator.validateAll()
        const additionalFieldRefs = this.$refs.additionalField
        if (additionalFieldRefs) {
          const fields = Array.isArray(additionalFieldRefs) ? additionalFieldRefs : [additionalFieldRefs]
          const p = fields.filter(field => field.validate).map(field => field.validate()) as Array<Promise<boolean>>
          isValid = isValid && await Promise.race(p)
        }
        if (isValid) {
          const application: VacancyApplicationFormData = {
            comment: this.letter.trim() || `Hi there! I'd like to apply for this Job as I feel I am fully qualified for the position.`,
            files: [],
            recentFiles: [],
          }
          if (this.uploadedFile) {
            application.files.push({
              base64: this.uploadedFile.base64,
              description: this.uploadedFile.description,
            })
          } else if (this.cv) {
            application.recentFiles.push({ id: this.cv.key })
          }
          application.additionalData = Object.entries(this.additionalForm as { [key: string]: string})
            .map(([uuid, value]) => ({
              uuid,
              value: value || '',
              group: (this.vacancy as Vacancy).applicationForm.find(f => f.technical.uuid === uuid)?.group,
            }))
          this.sending = true
          if (this.onSuccess) {
            await this.onSuccess(application)
          }
          this.$emit('close')
        }
      } catch (e: any) {
        console.error(e)
      } finally {
        this.sending = false
      }
    },
  },
})
