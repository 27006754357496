import Vue from 'vue'
import CommonProfile from '@/models/user/CommonProfile'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import Job from '@/models-ts/job/Job'
import { REVIEWS_NAME, REVIEWS_FREELANCER, REVIEWS_CUSTOMER } from '@/constants/components/review'
import snackMixin from '@/mixins/snackMixin'
import { reviewCreate } from '@/api/review'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'

export default Vue.extend<any, any, any, any>({
  name: 'lx-leave-feedback-modal',
  mixins: [snackMixin],
  components: {
    UserInfo,
  },
  props: {
    fromFreelancer: Boolean,
    job: Job, // @deprecated
    profile: [CommonProfile, Object],
    service: GigOfferListItem, // @deprecated
    onSuccess: Function,
    name: String,
    jobId: Number,
    gigId: Number,
  },
  data () {
    return {
      comment: '',
      REVIEWS_NAME,
      REVIEWS_FREELANCER,
      REVIEWS_CUSTOMER,
      scores: {},
      sending: false,
    }
  },
  computed: {
    contractName () {
      return this.name || (this.job ? this.job.name : this.service?.name)
    },
    role () {
      return this.fromFreelancer ? 'customer' : 'freelancer'
    },
    reviewsCat () {
      return this.fromFreelancer ? REVIEWS_CUSTOMER : REVIEWS_FREELANCER
    },
    sendDisabled () {
      return Object.values(this.scores).length !== this.reviewsCat.length || Object.values(this.scores).some(score => !score)
    },
  },
  methods: {
    onReviewUpdate (scoreId: number, value: number) {
      if (this.sending) {
        return
      }
      Vue.set(this.scores, scoreId, value)
    },
    async onFormSubmit () {
      if (await this.$validator.validateAll()) {
        this.sending = true
        try {
          let rates = []
          for (let scoreId of this.reviewsCat) {
            rates.push({
              category: +scoreId,
              rate: this.scores[scoreId] || 1
            })
          }
          const review = await reviewCreate({
            jobId: this.jobId || this.job?.id,
            gigId: this.gigId || this.service?.job?.id,
            text: this.comment,
            rates,
          })
          if (this.onSuccess) {
            this.onSuccess(review)
          }
          this.$emit('close')
          this.openSnackbar({
            text: 'Your review has been submitted. Thank you for using LaborX!',
          })
        } catch (err) {
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: 'Error submitting review. Please try again.',
          })
        } finally {
          this.sending = false
        }
      }
    },
  }
})
