import { BACKEND_PRIVATE } from './base'

export interface ReportData {
  toUserId: number,
  text: string,
  files?: Array<Partial<UploadedFile>>,
}

export async function report (payload: ReportData) {
  return BACKEND_PRIVATE.post('me/complaints/create', { payload })
}
