import Vue from 'vue'
import snackMixin from '@/mixins/snackMixin'
import { declineApplicationAsCustomer, declineApplicationAsFreelancer } from '@/api/gig'

export default Vue.extend<any, any, any, any>({
  name: 'lx-cancel-gig-as-customer',
  mixins: [snackMixin],
  props: {
    userName: String,
    applicationId: Number,
    onSuccess: Function,
    asCustomer: Boolean,
  },
  data () {
    return {
      sending: false,
      reason: null,
      comment: '',
    }
  },
  computed: {
    options () {
      return this.asCustomer
        ? [
          `Talent doesn't respond`,
          `I don't want to interact with talent`,
          'Level of work is not acceptable',
          'Other...'
        ]
        : [
          `I'm too busy to complete this Gig`,
          `The customer is not responding`,
          `I don't want to engage with this customer`,
          'Our budgets do not align',
          `I don't have the skillset required`,
          'Other...',
        ]
    },
    isOther () {
      return this.reason === 'Other...'
    },
    backendReason () {
      return this.isOther ? this.comment : this.reason
    },
  },
  methods: {
    onOptionInput () {
      if (this.isOther) {
        this.$nextTick(() => {
          const commentEl = this.$refs.comment?.$el
          if (commentEl) {
            const textarea = commentEl.querySelector('textarea')
            if (textarea) {
              textarea.focus()
              textarea.scrollIntoViewIfNeeded()
            }
          }
        })
      }
    },
    onCancelClick () {
      this.$emit('close')
    },
    async onSubmit () {
      try {
        this.sending = true
        if (await this.$validator.validateAll()) {
          if (this.asCustomer) {
            await declineApplicationAsCustomer({
              id: this.applicationId,
              reason: this.backendReason,
            })
          } else {
            await declineApplicationAsFreelancer({
              id: this.applicationId,
              reason: this.backendReason,
            })
          }
          this.$emit('close')
          if (this.onSuccess) {
            this.onSuccess()
          }
        }
      } catch (e: any) {
        console.error(e)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Failed to cancel Gig',
        })
      } finally {
        this.sending = false
      }
    },
  },
})
