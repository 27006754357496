import { render, staticRenderFns } from "./LeaveFeedback.htm?vue&type=template&id=e8a30ace&scoped=true&"
import script from "./LeaveFeedback.ts?vue&type=script&lang=js&"
export * from "./LeaveFeedback.ts?vue&type=script&lang=js&"
import style0 from "./LeaveFeedback.scss?vue&type=style&index=0&id=e8a30ace&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8a30ace",
  null
  
)

export default component.exports