import { BACKEND_PRIVATE } from './base'

export async function reviewCreate ({ gigId, jobId, text, rates }) {
  return BACKEND_PRIVATE.post('/me/reviews/create', {
    payload: {
      job_id: jobId,
      gig_job_id: gigId,
      text,
      relations: {
        Rate: rates,
      },
    },
  })
}
