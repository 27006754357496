import axios from 'axios'

class Unsplash {
  constructor (accessKey) {
    this.accessKey = accessKey
    this.client = axios.create({
      baseURL: 'https://api.unsplash.com/',
      headers: { Authorization: `Client-ID ${accessKey}` },
    })
    this.client.interceptors.response.use(resp => resp.data)
  }

  /**
   * Get a single page of photo results for a query
   * https://unsplash.com/documentation#search-photos
   * @param {String} keyword Search terms
   * @returns {Object} unsplash response
   */
  async search ({ keyword, page = 1, limit = 16, orientation = 'landscape' }) {
    return this.client.get('/search/photos', {
      params: {
        query: keyword,
        per_page: limit,
        page,
        orientation,
      },
    })
  }
}

export default new Unsplash(process.env.VUE_APP_UNSPLASH_ACCESS_KEY)
